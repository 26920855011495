import React from "react";
import styled from "styled-components";
import { device,theme } from "../style/theme";

export default function Item(props) {
  return (
    <>
      <L href={props.link} target="_blank">
        <I>{props.icon}</I>
        <T>{props.children}</T>
      </L>
    </>
  );
}

// Link Component
const L = styled.a`
  font-size: 1rem;
  font-family: ${theme.font.primary};
  font-weight: bold;
  text-align: left;
  border: 1px solid ${theme.color.white};
  color: ${theme.color.white};
  border-radius: 6px;
  margin-bottom: 1.2rem;
  padding-top: 15px;
  width: 100%;
  height: 50px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    background-color: ${theme.color.white};
    color: ${theme.color.dark};
  }
`;

// Icon Component
const I = styled.span`
  position: relative;
  top: -5px;
  left: 0;
  margin: 0 1rem;
  > :first-child {
    width: 23px;
    height: 23px;
  }
`;

// Text Component
const T = styled.span`
  text-align: center;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 13rem;
  @media ${device.mobileS} {
    width: 11rem;
  }
`;
