export const theme = {
  color: {
    white: "#fff",
    dark: "#333",
    opacityDark: "rgb(0 0 0 / 6%)",
    muted: "rgb(255 255 255 / 75%)",
    lines: "rgb(255 255 255 / 20%)",
    shadow: "0px 5px 16px 4px rgb(0 0 0 / 10%)",
  },
  font: {
    primary: "sans-serif",
  },
};

const size = {
  mobileS: "320px",
  mobile: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
};
