import React from "react";
import styled from "styled-components";
import { theme } from "../style/theme";

export default function Top(props) {
  return (
    <>
      <D>
        <L href={props.link}>{props.children}</L>
      </D>
    </>
  );
}

// Div Component
const D = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  background-color: ${theme.color.opacityDark};
  padding: 0.3rem 0;
  border-bottom: 1px solid ${theme.color.lines};
`;

// Link Component
const L = styled.a`
  color: ${theme.color.white};
  font-family: ${theme.font.primary};
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
`;
