import React from "react";
import styled from "styled-components";
import { theme } from "../style/theme";

export default function PP(props) {
  return (
    <>
      <D>
        <I src={props.picture} alt="Profile Photo"/>
      </D>
    </>
  );
}

// Div Component
const D = styled.div`
  display: flex;
  justify-content: center;
`;

// Image Component
const I = styled.img`
  width: 100px;
  height: 100px;
  display: flex;
  border: 3px solid ${theme.color.white};
  border-radius: 100px;
  box-shadow: ${theme.color.shadow};
`;
