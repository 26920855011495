import React from "react";
import styled from "styled-components";
import { GlobalStyle } from "./style/global";
import { device, theme } from "./style/theme";

import Top from "./components/Top";
import Item from "./components/Item";
import PP from "./components/ProfilePicture";

import Profile from "./images/mevlutkara2.jpg";
import {
  Twitter,
  Linkedin,
  Youtube,
  Facebook,
  Instagram,
  Whatsapp,
  Envelope,
} from "@styled-icons/bootstrap/";

export default function App() {
  return (
    <>
      <Top link="/a">mevlutkara.com.tr</Top>
      <C>
        <PP picture={Profile} />
        <T>Mevlüt Kara</T>
        <U>ERÜ MBA</U>
        <D>
          Erciyes Anadolu Holding <br /> SAP Erp İş Uygulamları Müdürü
        </D>

        <Bar>
          <Item icon={<Whatsapp />} link="https://wa.me/905068012729">
            WhatsApp Mesaj Gönder
          </Item>

          <Item
            icon={<Linkedin />}
            link="https://www.linkedin.com/in/mevl%C3%BCt-kara-mba-0069b148/"
          >
            Linkedin Ağıma Katılın
          </Item>

          <Item icon={<Twitter />} link="https://www.twitter.com/bilgidepomm">
            Twitter Takip Edin
          </Item>

          <Item icon={<Instagram />} link="https://instagram.com/mevlut.kara">
            Instagram Takip Edin
          </Item>

          <Item
            icon={<Youtube />}
            link="https://www.youtube.com/channel/UCWFAnWYStMuOez6Tn0UF-Dg"
          >
            Youtube Abone Olun
          </Item>

          <Item
            icon={<Facebook />}
            link="https://www.facebook.com/mevlut.kara.710"
          >
            Facebook Takip Edin
          </Item>

          <Item icon={<Envelope />} link="mailto:bilgidepomm@gmail.com">
            E-Posta Gönderin
          </Item>
        </Bar>
      </C>
      <GlobalStyle />
    </>
  );
}

// Container Component
const C = styled.div`
  margin: 5rem auto;
  padding: 4rem 1em;
  max-width: 25rem;
  border-radius: 16px;
  background-color: ${theme.color.opacityDark};
  border: 1px solid ${theme.color.lines};
  transition: all 250ms ease-in-out;
  @media ${device.laptopL} {
    margin: 4rem auto;
    padding: 3rem 1rem;
  }
  @media ${device.laptop} {
    margin: 3rem auto;
    padding: 2.5rem 1rem;
  }
  @media ${device.mobile} {
    margin: 2.5rem auto;
    padding: 2.5rem 1rem;
  }
  @media ${device.mobileS} {
    margin: 2.5rem auto;
    padding: 2rem 1rem;
  }
`;

// Title Component
const T = styled.h1`
  font-size: 1.5em;
  font-family: sans-serif;
  text-align: center;
  color: ${theme.color.white};
  margin-top: 1.5rem;
  margin-bottom: 0;
`;

// User Name Component
const U = styled.p`
  font-size: 1.2rem;
  font-family: sans-serif;
  text-align: center;
  color: ${theme.color.muted};
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

// Description Component
const D = styled.p`
  font-size: 1.2em;
  font-family: sans-serif;
  line-height: 1.6rem;
  text-align: center;
  color: ${theme.color.white};
`;

// Links Bar Component
const Bar = styled.div`
  padding: 1rem 0;
  margin: 0 auto;
  max-width: 20rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
