import { createGlobalStyle } from "styled-components";
// Background Image
import BG from "../images/background.jpeg"; 

export const GlobalStyle = createGlobalStyle`
*, ::after, ::before {
    box-sizing: border-box;
}

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  &:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -10;
    background: url(${BG}) no-repeat center center; 
    background-size: cover;
    transition: all 250ms ease-in-out;
  }
  }
`;
